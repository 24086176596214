

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}


@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-sm,
.gx-sm-sm {
    --bs-gutter-x: 0.33335rem;
  }

  .g-sm-sm,
.gy-sm-sm {
    --bs-gutter-y: 0.33335rem;
  }

  .g-sm-3px,
.gx-sm-3px {
    --bs-gutter-x: 3px;
  }

  .g-sm-3px,
.gy-sm-3px {
    --bs-gutter-y: 3px;
  }

  .g-sm-15px,
.gx-sm-15px {
    --bs-gutter-x: 15px;
  }

  .g-sm-15px,
.gy-sm-15px {
    --bs-gutter-y: 15px;
  }

  .g-sm-25px,
.gx-sm-25px {
    --bs-gutter-x: 25px;
  }

  .g-sm-25px,
.gy-sm-25px {
    --bs-gutter-y: 25px;
  }

  .g-sm-35px,
.gx-sm-35px {
    --bs-gutter-x: 35px;
  }

  .g-sm-35px,
.gy-sm-35px {
    --bs-gutter-y: 35px;
  }

  .g-sm-n15,
.gx-sm-n15 {
    --bs-gutter-x: -15px;
  }

  .g-sm-n15,
.gy-sm-n15 {
    --bs-gutter-y: -15px;
  }

  .g-sm-n1,
.gx-sm-n1 {
    --bs-gutter-x: -10px;
  }

  .g-sm-n1,
.gy-sm-n1 {
    --bs-gutter-y: -10px;
  }

  .g-sm-n5,
.gx-sm-n5 {
    --bs-gutter-x: -5px;
  }

  .g-sm-n5,
.gy-sm-n5 {
    --bs-gutter-y: -5px;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.6667rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.6667rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 1.3334rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 1.3334rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 2.0001rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 2.0001rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 2.6668rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 2.6668rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3.3335rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3.3335rem;
  }

  .g-sm-6,
.gx-sm-6 {
    --bs-gutter-x: 4.0002rem;
  }

  .g-sm-6,
.gy-sm-6 {
    --bs-gutter-y: 4.0002rem;
  }

  .g-sm-7,
.gx-sm-7 {
    --bs-gutter-x: 4.6669rem;
  }

  .g-sm-7,
.gy-sm-7 {
    --bs-gutter-y: 4.6669rem;
  }

  .g-sm-8,
.gx-sm-8 {
    --bs-gutter-x: 5.3336rem;
  }

  .g-sm-8,
.gy-sm-8 {
    --bs-gutter-y: 5.3336rem;
  }

  .g-sm-9,
.gx-sm-9 {
    --bs-gutter-x: 6.0003rem;
  }

  .g-sm-9,
.gy-sm-9 {
    --bs-gutter-y: 6.0003rem;
  }

  .g-sm-10,
.gx-sm-10 {
    --bs-gutter-x: 6.667rem;
  }

  .g-sm-10,
.gy-sm-10 {
    --bs-gutter-y: 6.667rem;
  }

  .g-sm-gutter,
.gx-sm-gutter {
    --bs-gutter-x: 2.133rem;
  }

  .g-sm-gutter,
.gy-sm-gutter {
    --bs-gutter-y: 2.133rem;
  }

  .g-sm-out-container,
.gx-sm-out-container {
    --bs-gutter-x: calc(50% - 50vw);
  }

  .g-sm-out-container,
.gy-sm-out-container {
    --bs-gutter-y: calc(50% - 50vw);
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-sm,
.gx-md-sm {
    --bs-gutter-x: 0.33335rem;
  }

  .g-md-sm,
.gy-md-sm {
    --bs-gutter-y: 0.33335rem;
  }

  .g-md-3px,
.gx-md-3px {
    --bs-gutter-x: 3px;
  }

  .g-md-3px,
.gy-md-3px {
    --bs-gutter-y: 3px;
  }

  .g-md-15px,
.gx-md-15px {
    --bs-gutter-x: 15px;
  }

  .g-md-15px,
.gy-md-15px {
    --bs-gutter-y: 15px;
  }

  .g-md-25px,
.gx-md-25px {
    --bs-gutter-x: 25px;
  }

  .g-md-25px,
.gy-md-25px {
    --bs-gutter-y: 25px;
  }

  .g-md-35px,
.gx-md-35px {
    --bs-gutter-x: 35px;
  }

  .g-md-35px,
.gy-md-35px {
    --bs-gutter-y: 35px;
  }

  .g-md-n15,
.gx-md-n15 {
    --bs-gutter-x: -15px;
  }

  .g-md-n15,
.gy-md-n15 {
    --bs-gutter-y: -15px;
  }

  .g-md-n1,
.gx-md-n1 {
    --bs-gutter-x: -10px;
  }

  .g-md-n1,
.gy-md-n1 {
    --bs-gutter-y: -10px;
  }

  .g-md-n5,
.gx-md-n5 {
    --bs-gutter-x: -5px;
  }

  .g-md-n5,
.gy-md-n5 {
    --bs-gutter-y: -5px;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.6667rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.6667rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 1.3334rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 1.3334rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 2.0001rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 2.0001rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 2.6668rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 2.6668rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3.3335rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3.3335rem;
  }

  .g-md-6,
.gx-md-6 {
    --bs-gutter-x: 4.0002rem;
  }

  .g-md-6,
.gy-md-6 {
    --bs-gutter-y: 4.0002rem;
  }

  .g-md-7,
.gx-md-7 {
    --bs-gutter-x: 4.6669rem;
  }

  .g-md-7,
.gy-md-7 {
    --bs-gutter-y: 4.6669rem;
  }

  .g-md-8,
.gx-md-8 {
    --bs-gutter-x: 5.3336rem;
  }

  .g-md-8,
.gy-md-8 {
    --bs-gutter-y: 5.3336rem;
  }

  .g-md-9,
.gx-md-9 {
    --bs-gutter-x: 6.0003rem;
  }

  .g-md-9,
.gy-md-9 {
    --bs-gutter-y: 6.0003rem;
  }

  .g-md-10,
.gx-md-10 {
    --bs-gutter-x: 6.667rem;
  }

  .g-md-10,
.gy-md-10 {
    --bs-gutter-y: 6.667rem;
  }

  .g-md-gutter,
.gx-md-gutter {
    --bs-gutter-x: 2.133rem;
  }

  .g-md-gutter,
.gy-md-gutter {
    --bs-gutter-y: 2.133rem;
  }

  .g-md-out-container,
.gx-md-out-container {
    --bs-gutter-x: calc(50% - 50vw);
  }

  .g-md-out-container,
.gy-md-out-container {
    --bs-gutter-y: calc(50% - 50vw);
  }
}


@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
.navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
.navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}


@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}


@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .position-sm-static {
    position: static !important;
  }

  .position-sm-relative {
    position: relative !important;
  }

  .position-sm-absolute {
    position: absolute !important;
  }

  .position-sm-fixed {
    position: fixed !important;
  }

  .position-sm-sticky {
    position: sticky !important;
  }

  .top-sm-0 {
    top: 0 !important;
  }

  .top-sm-25 {
    top: 25% !important;
  }

  .top-sm-50 {
    top: 50% !important;
  }

  .top-sm-75 {
    top: 75% !important;
  }

  .top-sm-100 {
    top: 100% !important;
  }

  .top-sm-10px {
    top: 10px !important;
  }

  .top-sm-90px {
    top: 90px !important;
  }

  .top-sm-n1 {
    top: -10px !important;
  }

  .top-sm-n2 {
    top: -20px !important;
  }

  .top-sm-3px {
    top: 3px !important;
  }

  .top-sm-60px {
    top: 60px !important;
  }

  .top-sm-70px {
    top: 70px !important;
  }

  .top-sm-80px {
    top: 80px !important;
  }

  .top-sm-100px {
    top: 100px !important;
  }

  .top-sm-13px {
    top: 13px !important;
  }

  .top-sm-15px {
    top: 15px !important;
  }

  .top-sm-n3px {
    top: -3px !important;
  }

  .top-sm-sm {
    top: 5px !important;
  }

  .bottom-sm-0 {
    bottom: 0 !important;
  }

  .bottom-sm-25 {
    bottom: 25% !important;
  }

  .bottom-sm-50 {
    bottom: 50% !important;
  }

  .bottom-sm-75 {
    bottom: 75% !important;
  }

  .bottom-sm-100 {
    bottom: 100% !important;
  }

  .bottom-sm-10px {
    bottom: 10px !important;
  }

  .bottom-sm-90px {
    bottom: 90px !important;
  }

  .bottom-sm-n1 {
    bottom: -10px !important;
  }

  .bottom-sm-n2 {
    bottom: -20px !important;
  }

  .bottom-sm-3px {
    bottom: 3px !important;
  }

  .bottom-sm-60px {
    bottom: 60px !important;
  }

  .bottom-sm-70px {
    bottom: 70px !important;
  }

  .bottom-sm-80px {
    bottom: 80px !important;
  }

  .bottom-sm-100px {
    bottom: 100px !important;
  }

  .bottom-sm-13px {
    bottom: 13px !important;
  }

  .bottom-sm-15px {
    bottom: 15px !important;
  }

  .bottom-sm-n3px {
    bottom: -3px !important;
  }

  .bottom-sm-sm {
    bottom: 5px !important;
  }

  .start-sm-0 {
    left: 0 !important;
  }

  .start-sm-25 {
    left: 25% !important;
  }

  .start-sm-50 {
    left: 50% !important;
  }

  .start-sm-75 {
    left: 75% !important;
  }

  .start-sm-100 {
    left: 100% !important;
  }

  .start-sm-10px {
    left: 10px !important;
  }

  .start-sm-90px {
    left: 90px !important;
  }

  .start-sm-n1 {
    left: -10px !important;
  }

  .start-sm-n2 {
    left: -20px !important;
  }

  .start-sm-3px {
    left: 3px !important;
  }

  .start-sm-60px {
    left: 60px !important;
  }

  .start-sm-70px {
    left: 70px !important;
  }

  .start-sm-80px {
    left: 80px !important;
  }

  .start-sm-100px {
    left: 100px !important;
  }

  .start-sm-13px {
    left: 13px !important;
  }

  .start-sm-15px {
    left: 15px !important;
  }

  .start-sm-n3px {
    left: -3px !important;
  }

  .start-sm-sm {
    left: 5px !important;
  }

  .end-sm-0 {
    right: 0 !important;
  }

  .end-sm-25 {
    right: 25% !important;
  }

  .end-sm-50 {
    right: 50% !important;
  }

  .end-sm-75 {
    right: 75% !important;
  }

  .end-sm-100 {
    right: 100% !important;
  }

  .end-sm-10px {
    right: 10px !important;
  }

  .end-sm-90px {
    right: 90px !important;
  }

  .end-sm-n1 {
    right: -10px !important;
  }

  .end-sm-n2 {
    right: -20px !important;
  }

  .end-sm-3px {
    right: 3px !important;
  }

  .end-sm-60px {
    right: 60px !important;
  }

  .end-sm-70px {
    right: 70px !important;
  }

  .end-sm-80px {
    right: 80px !important;
  }

  .end-sm-100px {
    right: 100px !important;
  }

  .end-sm-13px {
    right: 13px !important;
  }

  .end-sm-15px {
    right: 15px !important;
  }

  .end-sm-n3px {
    right: -3px !important;
  }

  .end-sm-sm {
    right: 5px !important;
  }

  .translate-middle-sm {
    transform: translate(-50%, -50%) !important;
  }

  .translate-middle-sm-x {
    transform: translateX(-50%) !important;
  }

  .translate-middle-sm-y {
    transform: translateY(-50%) !important;
  }

  .border-sm {
    border: 1px solid #d6d6d6 !important;
  }

  .border-sm-0 {
    border: 0 !important;
  }

  .border-top-sm {
    border-top: 1px solid #d6d6d6 !important;
  }

  .border-top-sm-0 {
    border-top: 0 !important;
  }

  .border-end-sm {
    border-right: 1px solid #d6d6d6 !important;
  }

  .border-end-sm-0 {
    border-right: 0 !important;
  }

  .border-bottom-sm {
    border-bottom: 1px solid #d6d6d6 !important;
  }

  .border-bottom-sm-0 {
    border-bottom: 0 !important;
  }

  .border-start-sm {
    border-left: 1px solid #d6d6d6 !important;
  }

  .border-start-sm-0 {
    border-left: 0 !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-auto {
    width: auto !important;
  }

  .w-sm-sm {
    width: 5px !important;
  }

  .w-sm-20px {
    width: 20px !important;
  }

  .w-sm-15px {
    width: 15px !important;
  }

  .w-sm-25px {
    width: 25px !important;
  }

  .w-sm-35px {
    width: 35px !important;
  }

  .w-sm-40px {
    width: 40px !important;
  }

  .w-sm-45px {
    width: 45px !important;
  }

  .w-sm-60px {
    width: 60px !important;
  }

  .w-sm-90px {
    width: 90px !important;
  }

  .w-sm-100px {
    width: 100px !important;
  }

  .w-sm-120px {
    width: 120px !important;
  }

  .w-sm-150px {
    width: 150px !important;
  }

  .w-sm-160px {
    width: 160px !important;
  }

  .w-sm-200px {
    width: 200px !important;
  }

  .w-sm-300px {
    width: 300px !important;
  }

  .w-sm-10 {
    width: 10% !important;
  }

  .w-sm-15 {
    width: 15% !important;
  }

  .w-sm-20 {
    width: 20% !important;
  }

  .w-sm-30 {
    width: 30% !important;
  }

  .w-sm-35 {
    width: 35% !important;
  }

  .w-sm-40 {
    width: 40% !important;
  }

  .w-sm-60 {
    width: 60% !important;
  }

  .w-sm-65 {
    width: 65% !important;
  }

  .w-sm-70 {
    width: 70% !important;
  }

  .w-sm-85 {
    width: 85% !important;
  }

  .w-sm-80 {
    width: 80% !important;
  }

  .w-sm-90 {
    width: 90% !important;
  }

  .maxw-sm-100 {
    max-width: 100% !important;
  }

  .maxw-sm-10 {
    max-width: 10% !important;
  }

  .maxw-sm-15 {
    max-width: 15% !important;
  }

  .maxw-sm-20 {
    max-width: 20% !important;
  }

  .maxw-sm-30 {
    max-width: 30% !important;
  }

  .maxw-sm-35 {
    max-width: 35% !important;
  }

  .maxw-sm-40 {
    max-width: 40% !important;
  }

  .maxw-sm-50 {
    max-width: 50% !important;
  }

  .maxw-sm-60 {
    max-width: 60% !important;
  }

  .maxw-sm-70 {
    max-width: 70% !important;
  }

  .maxw-sm-85 {
    max-width: 85% !important;
  }

  .maxw-sm-80 {
    max-width: 80% !important;
  }

  .maxw-sm-90 {
    max-width: 90% !important;
  }

  .maxw-sm-220 {
    max-width: 220px !important;
  }

  .maxw-sm-400 {
    max-width: 400px !important;
  }

  .maxw-sm-300 {
    max-width: 300px !important;
  }

  .maxw-sm-700 {
    max-width: 700px !important;
  }

  .h-sm-25 {
    height: 25% !important;
  }

  .h-sm-50 {
    height: 50% !important;
  }

  .h-sm-75 {
    height: 75% !important;
  }

  .h-sm-100 {
    height: 100% !important;
  }

  .h-sm-auto {
    height: auto !important;
  }

  .h-sm-n40 {
    height: 40px !important;
  }

  .h-sm-n49 {
    height: 49px !important;
  }

  .h-sm-15px {
    height: 15px !important;
  }

  .h-sm-n60 {
    height: 60px !important;
  }

  .h-sm-n80 {
    height: 80px !important;
  }

  .h-sm-50vh {
    height: 50vh !important;
  }

  .h-sm-70vh {
    height: 70vh !important;
  }

  .h-sm-480 {
    height: 480px !important;
  }

  .h-sm-n120 {
    height: 120px !important;
  }

  .mh-sm-350 {
    max-height: 350px !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-sm {
    gap: 0.33335rem !important;
  }

  .gap-sm-3px {
    gap: 3px !important;
  }

  .gap-sm-15px {
    gap: 15px !important;
  }

  .gap-sm-25px {
    gap: 25px !important;
  }

  .gap-sm-35px {
    gap: 35px !important;
  }

  .gap-sm-n15 {
    gap: -15px !important;
  }

  .gap-sm-n1 {
    gap: -10px !important;
  }

  .gap-sm-n5 {
    gap: -5px !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.6667rem !important;
  }

  .gap-sm-2 {
    gap: 1.3334rem !important;
  }

  .gap-sm-3 {
    gap: 2.0001rem !important;
  }

  .gap-sm-4 {
    gap: 2.6668rem !important;
  }

  .gap-sm-5 {
    gap: 3.3335rem !important;
  }

  .gap-sm-6 {
    gap: 4.0002rem !important;
  }

  .gap-sm-7 {
    gap: 4.6669rem !important;
  }

  .gap-sm-8 {
    gap: 5.3336rem !important;
  }

  .gap-sm-9 {
    gap: 6.0003rem !important;
  }

  .gap-sm-10 {
    gap: 6.667rem !important;
  }

  .gap-sm-gutter {
    gap: 2.133rem !important;
  }

  .gap-sm-out-container {
    gap: calc(50% - 50vw) !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-sm {
    margin: 0.33335rem !important;
  }

  .m-sm-3px {
    margin: 3px !important;
  }

  .m-sm-15px {
    margin: 15px !important;
  }

  .m-sm-25px {
    margin: 25px !important;
  }

  .m-sm-35px {
    margin: 35px !important;
  }

  .m-sm-n15 {
    margin: -15px !important;
  }

  .m-sm-n1 {
    margin: -10px !important;
  }

  .m-sm-n5 {
    margin: -5px !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.6667rem !important;
  }

  .m-sm-2 {
    margin: 1.3334rem !important;
  }

  .m-sm-3 {
    margin: 2.0001rem !important;
  }

  .m-sm-4 {
    margin: 2.6668rem !important;
  }

  .m-sm-5 {
    margin: 3.3335rem !important;
  }

  .m-sm-6 {
    margin: 4.0002rem !important;
  }

  .m-sm-7 {
    margin: 4.6669rem !important;
  }

  .m-sm-8 {
    margin: 5.3336rem !important;
  }

  .m-sm-9 {
    margin: 6.0003rem !important;
  }

  .m-sm-10 {
    margin: 6.667rem !important;
  }

  .m-sm-gutter {
    margin: 2.133rem !important;
  }

  .m-sm-out-container {
    margin: calc(50% - 50vw) !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-sm {
    margin-right: 0.33335rem !important;
    margin-left: 0.33335rem !important;
  }

  .mx-sm-3px {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }

  .mx-sm-15px {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }

  .mx-sm-25px {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }

  .mx-sm-35px {
    margin-right: 35px !important;
    margin-left: 35px !important;
  }

  .mx-sm-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }

  .mx-sm-n1 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }

  .mx-sm-n5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.6667rem !important;
    margin-left: 0.6667rem !important;
  }

  .mx-sm-2 {
    margin-right: 1.3334rem !important;
    margin-left: 1.3334rem !important;
  }

  .mx-sm-3 {
    margin-right: 2.0001rem !important;
    margin-left: 2.0001rem !important;
  }

  .mx-sm-4 {
    margin-right: 2.6668rem !important;
    margin-left: 2.6668rem !important;
  }

  .mx-sm-5 {
    margin-right: 3.3335rem !important;
    margin-left: 3.3335rem !important;
  }

  .mx-sm-6 {
    margin-right: 4.0002rem !important;
    margin-left: 4.0002rem !important;
  }

  .mx-sm-7 {
    margin-right: 4.6669rem !important;
    margin-left: 4.6669rem !important;
  }

  .mx-sm-8 {
    margin-right: 5.3336rem !important;
    margin-left: 5.3336rem !important;
  }

  .mx-sm-9 {
    margin-right: 6.0003rem !important;
    margin-left: 6.0003rem !important;
  }

  .mx-sm-10 {
    margin-right: 6.667rem !important;
    margin-left: 6.667rem !important;
  }

  .mx-sm-gutter {
    margin-right: 2.133rem !important;
    margin-left: 2.133rem !important;
  }

  .mx-sm-out-container {
    margin-right: calc(50% - 50vw) !important;
    margin-left: calc(50% - 50vw) !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-sm {
    margin-top: 0.33335rem !important;
    margin-bottom: 0.33335rem !important;
  }

  .my-sm-3px {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .my-sm-15px {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .my-sm-25px {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .my-sm-35px {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .my-sm-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }

  .my-sm-n1 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }

  .my-sm-n5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.6667rem !important;
    margin-bottom: 0.6667rem !important;
  }

  .my-sm-2 {
    margin-top: 1.3334rem !important;
    margin-bottom: 1.3334rem !important;
  }

  .my-sm-3 {
    margin-top: 2.0001rem !important;
    margin-bottom: 2.0001rem !important;
  }

  .my-sm-4 {
    margin-top: 2.6668rem !important;
    margin-bottom: 2.6668rem !important;
  }

  .my-sm-5 {
    margin-top: 3.3335rem !important;
    margin-bottom: 3.3335rem !important;
  }

  .my-sm-6 {
    margin-top: 4.0002rem !important;
    margin-bottom: 4.0002rem !important;
  }

  .my-sm-7 {
    margin-top: 4.6669rem !important;
    margin-bottom: 4.6669rem !important;
  }

  .my-sm-8 {
    margin-top: 5.3336rem !important;
    margin-bottom: 5.3336rem !important;
  }

  .my-sm-9 {
    margin-top: 6.0003rem !important;
    margin-bottom: 6.0003rem !important;
  }

  .my-sm-10 {
    margin-top: 6.667rem !important;
    margin-bottom: 6.667rem !important;
  }

  .my-sm-gutter {
    margin-top: 2.133rem !important;
    margin-bottom: 2.133rem !important;
  }

  .my-sm-out-container {
    margin-top: calc(50% - 50vw) !important;
    margin-bottom: calc(50% - 50vw) !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-sm {
    margin-top: 0.33335rem !important;
  }

  .mt-sm-3px {
    margin-top: 3px !important;
  }

  .mt-sm-15px {
    margin-top: 15px !important;
  }

  .mt-sm-25px {
    margin-top: 25px !important;
  }

  .mt-sm-35px {
    margin-top: 35px !important;
  }

  .mt-sm-n15 {
    margin-top: -15px !important;
  }

  .mt-sm-n1 {
    margin-top: -10px !important;
  }

  .mt-sm-n5 {
    margin-top: -5px !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.6667rem !important;
  }

  .mt-sm-2 {
    margin-top: 1.3334rem !important;
  }

  .mt-sm-3 {
    margin-top: 2.0001rem !important;
  }

  .mt-sm-4 {
    margin-top: 2.6668rem !important;
  }

  .mt-sm-5 {
    margin-top: 3.3335rem !important;
  }

  .mt-sm-6 {
    margin-top: 4.0002rem !important;
  }

  .mt-sm-7 {
    margin-top: 4.6669rem !important;
  }

  .mt-sm-8 {
    margin-top: 5.3336rem !important;
  }

  .mt-sm-9 {
    margin-top: 6.0003rem !important;
  }

  .mt-sm-10 {
    margin-top: 6.667rem !important;
  }

  .mt-sm-gutter {
    margin-top: 2.133rem !important;
  }

  .mt-sm-out-container {
    margin-top: calc(50% - 50vw) !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-sm {
    margin-right: 0.33335rem !important;
  }

  .me-sm-3px {
    margin-right: 3px !important;
  }

  .me-sm-15px {
    margin-right: 15px !important;
  }

  .me-sm-25px {
    margin-right: 25px !important;
  }

  .me-sm-35px {
    margin-right: 35px !important;
  }

  .me-sm-n15 {
    margin-right: -15px !important;
  }

  .me-sm-n1 {
    margin-right: -10px !important;
  }

  .me-sm-n5 {
    margin-right: -5px !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.6667rem !important;
  }

  .me-sm-2 {
    margin-right: 1.3334rem !important;
  }

  .me-sm-3 {
    margin-right: 2.0001rem !important;
  }

  .me-sm-4 {
    margin-right: 2.6668rem !important;
  }

  .me-sm-5 {
    margin-right: 3.3335rem !important;
  }

  .me-sm-6 {
    margin-right: 4.0002rem !important;
  }

  .me-sm-7 {
    margin-right: 4.6669rem !important;
  }

  .me-sm-8 {
    margin-right: 5.3336rem !important;
  }

  .me-sm-9 {
    margin-right: 6.0003rem !important;
  }

  .me-sm-10 {
    margin-right: 6.667rem !important;
  }

  .me-sm-gutter {
    margin-right: 2.133rem !important;
  }

  .me-sm-out-container {
    margin-right: calc(50% - 50vw) !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-sm {
    margin-bottom: 0.33335rem !important;
  }

  .mb-sm-3px {
    margin-bottom: 3px !important;
  }

  .mb-sm-15px {
    margin-bottom: 15px !important;
  }

  .mb-sm-25px {
    margin-bottom: 25px !important;
  }

  .mb-sm-35px {
    margin-bottom: 35px !important;
  }

  .mb-sm-n15 {
    margin-bottom: -15px !important;
  }

  .mb-sm-n1 {
    margin-bottom: -10px !important;
  }

  .mb-sm-n5 {
    margin-bottom: -5px !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.6667rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 1.3334rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 2.0001rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 2.6668rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3.3335rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 4.0002rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 4.6669rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 5.3336rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 6.0003rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 6.667rem !important;
  }

  .mb-sm-gutter {
    margin-bottom: 2.133rem !important;
  }

  .mb-sm-out-container {
    margin-bottom: calc(50% - 50vw) !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-sm {
    margin-left: 0.33335rem !important;
  }

  .ms-sm-3px {
    margin-left: 3px !important;
  }

  .ms-sm-15px {
    margin-left: 15px !important;
  }

  .ms-sm-25px {
    margin-left: 25px !important;
  }

  .ms-sm-35px {
    margin-left: 35px !important;
  }

  .ms-sm-n15 {
    margin-left: -15px !important;
  }

  .ms-sm-n1 {
    margin-left: -10px !important;
  }

  .ms-sm-n5 {
    margin-left: -5px !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.6667rem !important;
  }

  .ms-sm-2 {
    margin-left: 1.3334rem !important;
  }

  .ms-sm-3 {
    margin-left: 2.0001rem !important;
  }

  .ms-sm-4 {
    margin-left: 2.6668rem !important;
  }

  .ms-sm-5 {
    margin-left: 3.3335rem !important;
  }

  .ms-sm-6 {
    margin-left: 4.0002rem !important;
  }

  .ms-sm-7 {
    margin-left: 4.6669rem !important;
  }

  .ms-sm-8 {
    margin-left: 5.3336rem !important;
  }

  .ms-sm-9 {
    margin-left: 6.0003rem !important;
  }

  .ms-sm-10 {
    margin-left: 6.667rem !important;
  }

  .ms-sm-gutter {
    margin-left: 2.133rem !important;
  }

  .ms-sm-out-container {
    margin-left: calc(50% - 50vw) !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-sm {
    padding: 0.33335rem !important;
  }

  .p-sm-3px {
    padding: 3px !important;
  }

  .p-sm-15px {
    padding: 15px !important;
  }

  .p-sm-25px {
    padding: 25px !important;
  }

  .p-sm-35px {
    padding: 35px !important;
  }

  .p-sm-n15 {
    padding: -15px !important;
  }

  .p-sm-n1 {
    padding: -10px !important;
  }

  .p-sm-n5 {
    padding: -5px !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.6667rem !important;
  }

  .p-sm-2 {
    padding: 1.3334rem !important;
  }

  .p-sm-3 {
    padding: 2.0001rem !important;
  }

  .p-sm-4 {
    padding: 2.6668rem !important;
  }

  .p-sm-5 {
    padding: 3.3335rem !important;
  }

  .p-sm-6 {
    padding: 4.0002rem !important;
  }

  .p-sm-7 {
    padding: 4.6669rem !important;
  }

  .p-sm-8 {
    padding: 5.3336rem !important;
  }

  .p-sm-9 {
    padding: 6.0003rem !important;
  }

  .p-sm-10 {
    padding: 6.667rem !important;
  }

  .p-sm-gutter {
    padding: 2.133rem !important;
  }

  .p-sm-out-container {
    padding: calc(50% - 50vw) !important;
  }

  .px-sm-sm {
    padding-right: 0.33335rem !important;
    padding-left: 0.33335rem !important;
  }

  .px-sm-3px {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }

  .px-sm-15px {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .px-sm-25px {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }

  .px-sm-35px {
    padding-right: 35px !important;
    padding-left: 35px !important;
  }

  .px-sm-n15 {
    padding-right: -15px !important;
    padding-left: -15px !important;
  }

  .px-sm-n1 {
    padding-right: -10px !important;
    padding-left: -10px !important;
  }

  .px-sm-n5 {
    padding-right: -5px !important;
    padding-left: -5px !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.6667rem !important;
    padding-left: 0.6667rem !important;
  }

  .px-sm-2 {
    padding-right: 1.3334rem !important;
    padding-left: 1.3334rem !important;
  }

  .px-sm-3 {
    padding-right: 2.0001rem !important;
    padding-left: 2.0001rem !important;
  }

  .px-sm-4 {
    padding-right: 2.6668rem !important;
    padding-left: 2.6668rem !important;
  }

  .px-sm-5 {
    padding-right: 3.3335rem !important;
    padding-left: 3.3335rem !important;
  }

  .px-sm-6 {
    padding-right: 4.0002rem !important;
    padding-left: 4.0002rem !important;
  }

  .px-sm-7 {
    padding-right: 4.6669rem !important;
    padding-left: 4.6669rem !important;
  }

  .px-sm-8 {
    padding-right: 5.3336rem !important;
    padding-left: 5.3336rem !important;
  }

  .px-sm-9 {
    padding-right: 6.0003rem !important;
    padding-left: 6.0003rem !important;
  }

  .px-sm-10 {
    padding-right: 6.667rem !important;
    padding-left: 6.667rem !important;
  }

  .px-sm-gutter {
    padding-right: 2.133rem !important;
    padding-left: 2.133rem !important;
  }

  .px-sm-out-container {
    padding-right: calc(50% - 50vw) !important;
    padding-left: calc(50% - 50vw) !important;
  }

  .py-sm-sm {
    padding-top: 0.33335rem !important;
    padding-bottom: 0.33335rem !important;
  }

  .py-sm-3px {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .py-sm-15px {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .py-sm-25px {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .py-sm-35px {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .py-sm-n15 {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }

  .py-sm-n1 {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }

  .py-sm-n5 {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.6667rem !important;
    padding-bottom: 0.6667rem !important;
  }

  .py-sm-2 {
    padding-top: 1.3334rem !important;
    padding-bottom: 1.3334rem !important;
  }

  .py-sm-3 {
    padding-top: 2.0001rem !important;
    padding-bottom: 2.0001rem !important;
  }

  .py-sm-4 {
    padding-top: 2.6668rem !important;
    padding-bottom: 2.6668rem !important;
  }

  .py-sm-5 {
    padding-top: 3.3335rem !important;
    padding-bottom: 3.3335rem !important;
  }

  .py-sm-6 {
    padding-top: 4.0002rem !important;
    padding-bottom: 4.0002rem !important;
  }

  .py-sm-7 {
    padding-top: 4.6669rem !important;
    padding-bottom: 4.6669rem !important;
  }

  .py-sm-8 {
    padding-top: 5.3336rem !important;
    padding-bottom: 5.3336rem !important;
  }

  .py-sm-9 {
    padding-top: 6.0003rem !important;
    padding-bottom: 6.0003rem !important;
  }

  .py-sm-10 {
    padding-top: 6.667rem !important;
    padding-bottom: 6.667rem !important;
  }

  .py-sm-gutter {
    padding-top: 2.133rem !important;
    padding-bottom: 2.133rem !important;
  }

  .py-sm-out-container {
    padding-top: calc(50% - 50vw) !important;
    padding-bottom: calc(50% - 50vw) !important;
  }

  .pt-sm-sm {
    padding-top: 0.33335rem !important;
  }

  .pt-sm-3px {
    padding-top: 3px !important;
  }

  .pt-sm-15px {
    padding-top: 15px !important;
  }

  .pt-sm-25px {
    padding-top: 25px !important;
  }

  .pt-sm-35px {
    padding-top: 35px !important;
  }

  .pt-sm-n15 {
    padding-top: -15px !important;
  }

  .pt-sm-n1 {
    padding-top: -10px !important;
  }

  .pt-sm-n5 {
    padding-top: -5px !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.6667rem !important;
  }

  .pt-sm-2 {
    padding-top: 1.3334rem !important;
  }

  .pt-sm-3 {
    padding-top: 2.0001rem !important;
  }

  .pt-sm-4 {
    padding-top: 2.6668rem !important;
  }

  .pt-sm-5 {
    padding-top: 3.3335rem !important;
  }

  .pt-sm-6 {
    padding-top: 4.0002rem !important;
  }

  .pt-sm-7 {
    padding-top: 4.6669rem !important;
  }

  .pt-sm-8 {
    padding-top: 5.3336rem !important;
  }

  .pt-sm-9 {
    padding-top: 6.0003rem !important;
  }

  .pt-sm-10 {
    padding-top: 6.667rem !important;
  }

  .pt-sm-gutter {
    padding-top: 2.133rem !important;
  }

  .pt-sm-out-container {
    padding-top: calc(50% - 50vw) !important;
  }

  .pe-sm-sm {
    padding-right: 0.33335rem !important;
  }

  .pe-sm-3px {
    padding-right: 3px !important;
  }

  .pe-sm-15px {
    padding-right: 15px !important;
  }

  .pe-sm-25px {
    padding-right: 25px !important;
  }

  .pe-sm-35px {
    padding-right: 35px !important;
  }

  .pe-sm-n15 {
    padding-right: -15px !important;
  }

  .pe-sm-n1 {
    padding-right: -10px !important;
  }

  .pe-sm-n5 {
    padding-right: -5px !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.6667rem !important;
  }

  .pe-sm-2 {
    padding-right: 1.3334rem !important;
  }

  .pe-sm-3 {
    padding-right: 2.0001rem !important;
  }

  .pe-sm-4 {
    padding-right: 2.6668rem !important;
  }

  .pe-sm-5 {
    padding-right: 3.3335rem !important;
  }

  .pe-sm-6 {
    padding-right: 4.0002rem !important;
  }

  .pe-sm-7 {
    padding-right: 4.6669rem !important;
  }

  .pe-sm-8 {
    padding-right: 5.3336rem !important;
  }

  .pe-sm-9 {
    padding-right: 6.0003rem !important;
  }

  .pe-sm-10 {
    padding-right: 6.667rem !important;
  }

  .pe-sm-gutter {
    padding-right: 2.133rem !important;
  }

  .pe-sm-out-container {
    padding-right: calc(50% - 50vw) !important;
  }

  .pb-sm-sm {
    padding-bottom: 0.33335rem !important;
  }

  .pb-sm-3px {
    padding-bottom: 3px !important;
  }

  .pb-sm-15px {
    padding-bottom: 15px !important;
  }

  .pb-sm-25px {
    padding-bottom: 25px !important;
  }

  .pb-sm-35px {
    padding-bottom: 35px !important;
  }

  .pb-sm-n15 {
    padding-bottom: -15px !important;
  }

  .pb-sm-n1 {
    padding-bottom: -10px !important;
  }

  .pb-sm-n5 {
    padding-bottom: -5px !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.6667rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 1.3334rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 2.0001rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 2.6668rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3.3335rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 4.0002rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 4.6669rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 5.3336rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 6.0003rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 6.667rem !important;
  }

  .pb-sm-gutter {
    padding-bottom: 2.133rem !important;
  }

  .pb-sm-out-container {
    padding-bottom: calc(50% - 50vw) !important;
  }

  .ps-sm-sm {
    padding-left: 0.33335rem !important;
  }

  .ps-sm-3px {
    padding-left: 3px !important;
  }

  .ps-sm-15px {
    padding-left: 15px !important;
  }

  .ps-sm-25px {
    padding-left: 25px !important;
  }

  .ps-sm-35px {
    padding-left: 35px !important;
  }

  .ps-sm-n15 {
    padding-left: -15px !important;
  }

  .ps-sm-n1 {
    padding-left: -10px !important;
  }

  .ps-sm-n5 {
    padding-left: -5px !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.6667rem !important;
  }

  .ps-sm-2 {
    padding-left: 1.3334rem !important;
  }

  .ps-sm-3 {
    padding-left: 2.0001rem !important;
  }

  .ps-sm-4 {
    padding-left: 2.6668rem !important;
  }

  .ps-sm-5 {
    padding-left: 3.3335rem !important;
  }

  .ps-sm-6 {
    padding-left: 4.0002rem !important;
  }

  .ps-sm-7 {
    padding-left: 4.6669rem !important;
  }

  .ps-sm-8 {
    padding-left: 5.3336rem !important;
  }

  .ps-sm-9 {
    padding-left: 6.0003rem !important;
  }

  .ps-sm-10 {
    padding-left: 6.667rem !important;
  }

  .ps-sm-gutter {
    padding-left: 2.133rem !important;
  }

  .ps-sm-out-container {
    padding-left: calc(50% - 50vw) !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .fs-sm-1 {
    font-size: 2.5rem !important;
  }

  .fs-sm-2 {
    font-size: 2rem !important;
  }

  .fs-sm-3 {
    font-size: 1.75rem !important;
  }

  .fs-sm-4 {
    font-size: 1.5rem !important;
  }

  .fs-sm-5 {
    font-size: 1.25rem !important;
  }

  .fs-sm-6 {
    font-size: 1.125rem !important;
  }

  .fs-sm-7 {
    font-size: 1rem !important;
  }

  .fs-sm-lg {
    font-size: 1.875rem !important;
  }

  .fs-sm-sm {
    font-size: 0.875rem !important;
  }

  .fs-sm-xs {
    font-size: 0.75rem !important;
  }

  .fs-sm-xxs {
    font-size: 0.625rem !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .position-md-static {
    position: static !important;
  }

  .position-md-relative {
    position: relative !important;
  }

  .position-md-absolute {
    position: absolute !important;
  }

  .position-md-fixed {
    position: fixed !important;
  }

  .position-md-sticky {
    position: sticky !important;
  }

  .top-md-0 {
    top: 0 !important;
  }

  .top-md-25 {
    top: 25% !important;
  }

  .top-md-50 {
    top: 50% !important;
  }

  .top-md-75 {
    top: 75% !important;
  }

  .top-md-100 {
    top: 100% !important;
  }

  .top-md-10px {
    top: 10px !important;
  }

  .top-md-90px {
    top: 90px !important;
  }

  .top-md-n1 {
    top: -10px !important;
  }

  .top-md-n2 {
    top: -20px !important;
  }

  .top-md-3px {
    top: 3px !important;
  }

  .top-md-60px {
    top: 60px !important;
  }

  .top-md-70px {
    top: 70px !important;
  }

  .top-md-80px {
    top: 80px !important;
  }

  .top-md-100px {
    top: 100px !important;
  }

  .top-md-13px {
    top: 13px !important;
  }

  .top-md-15px {
    top: 15px !important;
  }

  .top-md-n3px {
    top: -3px !important;
  }

  .top-md-sm {
    top: 5px !important;
  }

  .bottom-md-0 {
    bottom: 0 !important;
  }

  .bottom-md-25 {
    bottom: 25% !important;
  }

  .bottom-md-50 {
    bottom: 50% !important;
  }

  .bottom-md-75 {
    bottom: 75% !important;
  }

  .bottom-md-100 {
    bottom: 100% !important;
  }

  .bottom-md-10px {
    bottom: 10px !important;
  }

  .bottom-md-90px {
    bottom: 90px !important;
  }

  .bottom-md-n1 {
    bottom: -10px !important;
  }

  .bottom-md-n2 {
    bottom: -20px !important;
  }

  .bottom-md-3px {
    bottom: 3px !important;
  }

  .bottom-md-60px {
    bottom: 60px !important;
  }

  .bottom-md-70px {
    bottom: 70px !important;
  }

  .bottom-md-80px {
    bottom: 80px !important;
  }

  .bottom-md-100px {
    bottom: 100px !important;
  }

  .bottom-md-13px {
    bottom: 13px !important;
  }

  .bottom-md-15px {
    bottom: 15px !important;
  }

  .bottom-md-n3px {
    bottom: -3px !important;
  }

  .bottom-md-sm {
    bottom: 5px !important;
  }

  .start-md-0 {
    left: 0 !important;
  }

  .start-md-25 {
    left: 25% !important;
  }

  .start-md-50 {
    left: 50% !important;
  }

  .start-md-75 {
    left: 75% !important;
  }

  .start-md-100 {
    left: 100% !important;
  }

  .start-md-10px {
    left: 10px !important;
  }

  .start-md-90px {
    left: 90px !important;
  }

  .start-md-n1 {
    left: -10px !important;
  }

  .start-md-n2 {
    left: -20px !important;
  }

  .start-md-3px {
    left: 3px !important;
  }

  .start-md-60px {
    left: 60px !important;
  }

  .start-md-70px {
    left: 70px !important;
  }

  .start-md-80px {
    left: 80px !important;
  }

  .start-md-100px {
    left: 100px !important;
  }

  .start-md-13px {
    left: 13px !important;
  }

  .start-md-15px {
    left: 15px !important;
  }

  .start-md-n3px {
    left: -3px !important;
  }

  .start-md-sm {
    left: 5px !important;
  }

  .end-md-0 {
    right: 0 !important;
  }

  .end-md-25 {
    right: 25% !important;
  }

  .end-md-50 {
    right: 50% !important;
  }

  .end-md-75 {
    right: 75% !important;
  }

  .end-md-100 {
    right: 100% !important;
  }

  .end-md-10px {
    right: 10px !important;
  }

  .end-md-90px {
    right: 90px !important;
  }

  .end-md-n1 {
    right: -10px !important;
  }

  .end-md-n2 {
    right: -20px !important;
  }

  .end-md-3px {
    right: 3px !important;
  }

  .end-md-60px {
    right: 60px !important;
  }

  .end-md-70px {
    right: 70px !important;
  }

  .end-md-80px {
    right: 80px !important;
  }

  .end-md-100px {
    right: 100px !important;
  }

  .end-md-13px {
    right: 13px !important;
  }

  .end-md-15px {
    right: 15px !important;
  }

  .end-md-n3px {
    right: -3px !important;
  }

  .end-md-sm {
    right: 5px !important;
  }

  .translate-middle-md {
    transform: translate(-50%, -50%) !important;
  }

  .translate-middle-md-x {
    transform: translateX(-50%) !important;
  }

  .translate-middle-md-y {
    transform: translateY(-50%) !important;
  }

  .border-md {
    border: 1px solid #d6d6d6 !important;
  }

  .border-md-0 {
    border: 0 !important;
  }

  .border-top-md {
    border-top: 1px solid #d6d6d6 !important;
  }

  .border-top-md-0 {
    border-top: 0 !important;
  }

  .border-end-md {
    border-right: 1px solid #d6d6d6 !important;
  }

  .border-end-md-0 {
    border-right: 0 !important;
  }

  .border-bottom-md {
    border-bottom: 1px solid #d6d6d6 !important;
  }

  .border-bottom-md-0 {
    border-bottom: 0 !important;
  }

  .border-start-md {
    border-left: 1px solid #d6d6d6 !important;
  }

  .border-start-md-0 {
    border-left: 0 !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .w-md-auto {
    width: auto !important;
  }

  .w-md-sm {
    width: 5px !important;
  }

  .w-md-20px {
    width: 20px !important;
  }

  .w-md-15px {
    width: 15px !important;
  }

  .w-md-25px {
    width: 25px !important;
  }

  .w-md-35px {
    width: 35px !important;
  }

  .w-md-40px {
    width: 40px !important;
  }

  .w-md-45px {
    width: 45px !important;
  }

  .w-md-60px {
    width: 60px !important;
  }

  .w-md-90px {
    width: 90px !important;
  }

  .w-md-100px {
    width: 100px !important;
  }

  .w-md-120px {
    width: 120px !important;
  }

  .w-md-150px {
    width: 150px !important;
  }

  .w-md-160px {
    width: 160px !important;
  }

  .w-md-200px {
    width: 200px !important;
  }

  .w-md-300px {
    width: 300px !important;
  }

  .w-md-10 {
    width: 10% !important;
  }

  .w-md-15 {
    width: 15% !important;
  }

  .w-md-20 {
    width: 20% !important;
  }

  .w-md-30 {
    width: 30% !important;
  }

  .w-md-35 {
    width: 35% !important;
  }

  .w-md-40 {
    width: 40% !important;
  }

  .w-md-60 {
    width: 60% !important;
  }

  .w-md-65 {
    width: 65% !important;
  }

  .w-md-70 {
    width: 70% !important;
  }

  .w-md-85 {
    width: 85% !important;
  }

  .w-md-80 {
    width: 80% !important;
  }

  .w-md-90 {
    width: 90% !important;
  }

  .maxw-md-100 {
    max-width: 100% !important;
  }

  .maxw-md-10 {
    max-width: 10% !important;
  }

  .maxw-md-15 {
    max-width: 15% !important;
  }

  .maxw-md-20 {
    max-width: 20% !important;
  }

  .maxw-md-30 {
    max-width: 30% !important;
  }

  .maxw-md-35 {
    max-width: 35% !important;
  }

  .maxw-md-40 {
    max-width: 40% !important;
  }

  .maxw-md-50 {
    max-width: 50% !important;
  }

  .maxw-md-60 {
    max-width: 60% !important;
  }

  .maxw-md-70 {
    max-width: 70% !important;
  }

  .maxw-md-85 {
    max-width: 85% !important;
  }

  .maxw-md-80 {
    max-width: 80% !important;
  }

  .maxw-md-90 {
    max-width: 90% !important;
  }

  .maxw-md-220 {
    max-width: 220px !important;
  }

  .maxw-md-400 {
    max-width: 400px !important;
  }

  .maxw-md-300 {
    max-width: 300px !important;
  }

  .maxw-md-700 {
    max-width: 700px !important;
  }

  .h-md-25 {
    height: 25% !important;
  }

  .h-md-50 {
    height: 50% !important;
  }

  .h-md-75 {
    height: 75% !important;
  }

  .h-md-100 {
    height: 100% !important;
  }

  .h-md-auto {
    height: auto !important;
  }

  .h-md-n40 {
    height: 40px !important;
  }

  .h-md-n49 {
    height: 49px !important;
  }

  .h-md-15px {
    height: 15px !important;
  }

  .h-md-n60 {
    height: 60px !important;
  }

  .h-md-n80 {
    height: 80px !important;
  }

  .h-md-50vh {
    height: 50vh !important;
  }

  .h-md-70vh {
    height: 70vh !important;
  }

  .h-md-480 {
    height: 480px !important;
  }

  .h-md-n120 {
    height: 120px !important;
  }

  .mh-md-350 {
    max-height: 350px !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-sm {
    gap: 0.33335rem !important;
  }

  .gap-md-3px {
    gap: 3px !important;
  }

  .gap-md-15px {
    gap: 15px !important;
  }

  .gap-md-25px {
    gap: 25px !important;
  }

  .gap-md-35px {
    gap: 35px !important;
  }

  .gap-md-n15 {
    gap: -15px !important;
  }

  .gap-md-n1 {
    gap: -10px !important;
  }

  .gap-md-n5 {
    gap: -5px !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.6667rem !important;
  }

  .gap-md-2 {
    gap: 1.3334rem !important;
  }

  .gap-md-3 {
    gap: 2.0001rem !important;
  }

  .gap-md-4 {
    gap: 2.6668rem !important;
  }

  .gap-md-5 {
    gap: 3.3335rem !important;
  }

  .gap-md-6 {
    gap: 4.0002rem !important;
  }

  .gap-md-7 {
    gap: 4.6669rem !important;
  }

  .gap-md-8 {
    gap: 5.3336rem !important;
  }

  .gap-md-9 {
    gap: 6.0003rem !important;
  }

  .gap-md-10 {
    gap: 6.667rem !important;
  }

  .gap-md-gutter {
    gap: 2.133rem !important;
  }

  .gap-md-out-container {
    gap: calc(50% - 50vw) !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-sm {
    margin: 0.33335rem !important;
  }

  .m-md-3px {
    margin: 3px !important;
  }

  .m-md-15px {
    margin: 15px !important;
  }

  .m-md-25px {
    margin: 25px !important;
  }

  .m-md-35px {
    margin: 35px !important;
  }

  .m-md-n15 {
    margin: -15px !important;
  }

  .m-md-n1 {
    margin: -10px !important;
  }

  .m-md-n5 {
    margin: -5px !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.6667rem !important;
  }

  .m-md-2 {
    margin: 1.3334rem !important;
  }

  .m-md-3 {
    margin: 2.0001rem !important;
  }

  .m-md-4 {
    margin: 2.6668rem !important;
  }

  .m-md-5 {
    margin: 3.3335rem !important;
  }

  .m-md-6 {
    margin: 4.0002rem !important;
  }

  .m-md-7 {
    margin: 4.6669rem !important;
  }

  .m-md-8 {
    margin: 5.3336rem !important;
  }

  .m-md-9 {
    margin: 6.0003rem !important;
  }

  .m-md-10 {
    margin: 6.667rem !important;
  }

  .m-md-gutter {
    margin: 2.133rem !important;
  }

  .m-md-out-container {
    margin: calc(50% - 50vw) !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-sm {
    margin-right: 0.33335rem !important;
    margin-left: 0.33335rem !important;
  }

  .mx-md-3px {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }

  .mx-md-15px {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }

  .mx-md-25px {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }

  .mx-md-35px {
    margin-right: 35px !important;
    margin-left: 35px !important;
  }

  .mx-md-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }

  .mx-md-n1 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }

  .mx-md-n5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.6667rem !important;
    margin-left: 0.6667rem !important;
  }

  .mx-md-2 {
    margin-right: 1.3334rem !important;
    margin-left: 1.3334rem !important;
  }

  .mx-md-3 {
    margin-right: 2.0001rem !important;
    margin-left: 2.0001rem !important;
  }

  .mx-md-4 {
    margin-right: 2.6668rem !important;
    margin-left: 2.6668rem !important;
  }

  .mx-md-5 {
    margin-right: 3.3335rem !important;
    margin-left: 3.3335rem !important;
  }

  .mx-md-6 {
    margin-right: 4.0002rem !important;
    margin-left: 4.0002rem !important;
  }

  .mx-md-7 {
    margin-right: 4.6669rem !important;
    margin-left: 4.6669rem !important;
  }

  .mx-md-8 {
    margin-right: 5.3336rem !important;
    margin-left: 5.3336rem !important;
  }

  .mx-md-9 {
    margin-right: 6.0003rem !important;
    margin-left: 6.0003rem !important;
  }

  .mx-md-10 {
    margin-right: 6.667rem !important;
    margin-left: 6.667rem !important;
  }

  .mx-md-gutter {
    margin-right: 2.133rem !important;
    margin-left: 2.133rem !important;
  }

  .mx-md-out-container {
    margin-right: calc(50% - 50vw) !important;
    margin-left: calc(50% - 50vw) !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-sm {
    margin-top: 0.33335rem !important;
    margin-bottom: 0.33335rem !important;
  }

  .my-md-3px {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .my-md-15px {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .my-md-25px {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .my-md-35px {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .my-md-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }

  .my-md-n1 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }

  .my-md-n5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.6667rem !important;
    margin-bottom: 0.6667rem !important;
  }

  .my-md-2 {
    margin-top: 1.3334rem !important;
    margin-bottom: 1.3334rem !important;
  }

  .my-md-3 {
    margin-top: 2.0001rem !important;
    margin-bottom: 2.0001rem !important;
  }

  .my-md-4 {
    margin-top: 2.6668rem !important;
    margin-bottom: 2.6668rem !important;
  }

  .my-md-5 {
    margin-top: 3.3335rem !important;
    margin-bottom: 3.3335rem !important;
  }

  .my-md-6 {
    margin-top: 4.0002rem !important;
    margin-bottom: 4.0002rem !important;
  }

  .my-md-7 {
    margin-top: 4.6669rem !important;
    margin-bottom: 4.6669rem !important;
  }

  .my-md-8 {
    margin-top: 5.3336rem !important;
    margin-bottom: 5.3336rem !important;
  }

  .my-md-9 {
    margin-top: 6.0003rem !important;
    margin-bottom: 6.0003rem !important;
  }

  .my-md-10 {
    margin-top: 6.667rem !important;
    margin-bottom: 6.667rem !important;
  }

  .my-md-gutter {
    margin-top: 2.133rem !important;
    margin-bottom: 2.133rem !important;
  }

  .my-md-out-container {
    margin-top: calc(50% - 50vw) !important;
    margin-bottom: calc(50% - 50vw) !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-sm {
    margin-top: 0.33335rem !important;
  }

  .mt-md-3px {
    margin-top: 3px !important;
  }

  .mt-md-15px {
    margin-top: 15px !important;
  }

  .mt-md-25px {
    margin-top: 25px !important;
  }

  .mt-md-35px {
    margin-top: 35px !important;
  }

  .mt-md-n15 {
    margin-top: -15px !important;
  }

  .mt-md-n1 {
    margin-top: -10px !important;
  }

  .mt-md-n5 {
    margin-top: -5px !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.6667rem !important;
  }

  .mt-md-2 {
    margin-top: 1.3334rem !important;
  }

  .mt-md-3 {
    margin-top: 2.0001rem !important;
  }

  .mt-md-4 {
    margin-top: 2.6668rem !important;
  }

  .mt-md-5 {
    margin-top: 3.3335rem !important;
  }

  .mt-md-6 {
    margin-top: 4.0002rem !important;
  }

  .mt-md-7 {
    margin-top: 4.6669rem !important;
  }

  .mt-md-8 {
    margin-top: 5.3336rem !important;
  }

  .mt-md-9 {
    margin-top: 6.0003rem !important;
  }

  .mt-md-10 {
    margin-top: 6.667rem !important;
  }

  .mt-md-gutter {
    margin-top: 2.133rem !important;
  }

  .mt-md-out-container {
    margin-top: calc(50% - 50vw) !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-sm {
    margin-right: 0.33335rem !important;
  }

  .me-md-3px {
    margin-right: 3px !important;
  }

  .me-md-15px {
    margin-right: 15px !important;
  }

  .me-md-25px {
    margin-right: 25px !important;
  }

  .me-md-35px {
    margin-right: 35px !important;
  }

  .me-md-n15 {
    margin-right: -15px !important;
  }

  .me-md-n1 {
    margin-right: -10px !important;
  }

  .me-md-n5 {
    margin-right: -5px !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.6667rem !important;
  }

  .me-md-2 {
    margin-right: 1.3334rem !important;
  }

  .me-md-3 {
    margin-right: 2.0001rem !important;
  }

  .me-md-4 {
    margin-right: 2.6668rem !important;
  }

  .me-md-5 {
    margin-right: 3.3335rem !important;
  }

  .me-md-6 {
    margin-right: 4.0002rem !important;
  }

  .me-md-7 {
    margin-right: 4.6669rem !important;
  }

  .me-md-8 {
    margin-right: 5.3336rem !important;
  }

  .me-md-9 {
    margin-right: 6.0003rem !important;
  }

  .me-md-10 {
    margin-right: 6.667rem !important;
  }

  .me-md-gutter {
    margin-right: 2.133rem !important;
  }

  .me-md-out-container {
    margin-right: calc(50% - 50vw) !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-sm {
    margin-bottom: 0.33335rem !important;
  }

  .mb-md-3px {
    margin-bottom: 3px !important;
  }

  .mb-md-15px {
    margin-bottom: 15px !important;
  }

  .mb-md-25px {
    margin-bottom: 25px !important;
  }

  .mb-md-35px {
    margin-bottom: 35px !important;
  }

  .mb-md-n15 {
    margin-bottom: -15px !important;
  }

  .mb-md-n1 {
    margin-bottom: -10px !important;
  }

  .mb-md-n5 {
    margin-bottom: -5px !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.6667rem !important;
  }

  .mb-md-2 {
    margin-bottom: 1.3334rem !important;
  }

  .mb-md-3 {
    margin-bottom: 2.0001rem !important;
  }

  .mb-md-4 {
    margin-bottom: 2.6668rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3.3335rem !important;
  }

  .mb-md-6 {
    margin-bottom: 4.0002rem !important;
  }

  .mb-md-7 {
    margin-bottom: 4.6669rem !important;
  }

  .mb-md-8 {
    margin-bottom: 5.3336rem !important;
  }

  .mb-md-9 {
    margin-bottom: 6.0003rem !important;
  }

  .mb-md-10 {
    margin-bottom: 6.667rem !important;
  }

  .mb-md-gutter {
    margin-bottom: 2.133rem !important;
  }

  .mb-md-out-container {
    margin-bottom: calc(50% - 50vw) !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-sm {
    margin-left: 0.33335rem !important;
  }

  .ms-md-3px {
    margin-left: 3px !important;
  }

  .ms-md-15px {
    margin-left: 15px !important;
  }

  .ms-md-25px {
    margin-left: 25px !important;
  }

  .ms-md-35px {
    margin-left: 35px !important;
  }

  .ms-md-n15 {
    margin-left: -15px !important;
  }

  .ms-md-n1 {
    margin-left: -10px !important;
  }

  .ms-md-n5 {
    margin-left: -5px !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.6667rem !important;
  }

  .ms-md-2 {
    margin-left: 1.3334rem !important;
  }

  .ms-md-3 {
    margin-left: 2.0001rem !important;
  }

  .ms-md-4 {
    margin-left: 2.6668rem !important;
  }

  .ms-md-5 {
    margin-left: 3.3335rem !important;
  }

  .ms-md-6 {
    margin-left: 4.0002rem !important;
  }

  .ms-md-7 {
    margin-left: 4.6669rem !important;
  }

  .ms-md-8 {
    margin-left: 5.3336rem !important;
  }

  .ms-md-9 {
    margin-left: 6.0003rem !important;
  }

  .ms-md-10 {
    margin-left: 6.667rem !important;
  }

  .ms-md-gutter {
    margin-left: 2.133rem !important;
  }

  .ms-md-out-container {
    margin-left: calc(50% - 50vw) !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-sm {
    padding: 0.33335rem !important;
  }

  .p-md-3px {
    padding: 3px !important;
  }

  .p-md-15px {
    padding: 15px !important;
  }

  .p-md-25px {
    padding: 25px !important;
  }

  .p-md-35px {
    padding: 35px !important;
  }

  .p-md-n15 {
    padding: -15px !important;
  }

  .p-md-n1 {
    padding: -10px !important;
  }

  .p-md-n5 {
    padding: -5px !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.6667rem !important;
  }

  .p-md-2 {
    padding: 1.3334rem !important;
  }

  .p-md-3 {
    padding: 2.0001rem !important;
  }

  .p-md-4 {
    padding: 2.6668rem !important;
  }

  .p-md-5 {
    padding: 3.3335rem !important;
  }

  .p-md-6 {
    padding: 4.0002rem !important;
  }

  .p-md-7 {
    padding: 4.6669rem !important;
  }

  .p-md-8 {
    padding: 5.3336rem !important;
  }

  .p-md-9 {
    padding: 6.0003rem !important;
  }

  .p-md-10 {
    padding: 6.667rem !important;
  }

  .p-md-gutter {
    padding: 2.133rem !important;
  }

  .p-md-out-container {
    padding: calc(50% - 50vw) !important;
  }

  .px-md-sm {
    padding-right: 0.33335rem !important;
    padding-left: 0.33335rem !important;
  }

  .px-md-3px {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }

  .px-md-15px {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .px-md-25px {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }

  .px-md-35px {
    padding-right: 35px !important;
    padding-left: 35px !important;
  }

  .px-md-n15 {
    padding-right: -15px !important;
    padding-left: -15px !important;
  }

  .px-md-n1 {
    padding-right: -10px !important;
    padding-left: -10px !important;
  }

  .px-md-n5 {
    padding-right: -5px !important;
    padding-left: -5px !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.6667rem !important;
    padding-left: 0.6667rem !important;
  }

  .px-md-2 {
    padding-right: 1.3334rem !important;
    padding-left: 1.3334rem !important;
  }

  .px-md-3 {
    padding-right: 2.0001rem !important;
    padding-left: 2.0001rem !important;
  }

  .px-md-4 {
    padding-right: 2.6668rem !important;
    padding-left: 2.6668rem !important;
  }

  .px-md-5 {
    padding-right: 3.3335rem !important;
    padding-left: 3.3335rem !important;
  }

  .px-md-6 {
    padding-right: 4.0002rem !important;
    padding-left: 4.0002rem !important;
  }

  .px-md-7 {
    padding-right: 4.6669rem !important;
    padding-left: 4.6669rem !important;
  }

  .px-md-8 {
    padding-right: 5.3336rem !important;
    padding-left: 5.3336rem !important;
  }

  .px-md-9 {
    padding-right: 6.0003rem !important;
    padding-left: 6.0003rem !important;
  }

  .px-md-10 {
    padding-right: 6.667rem !important;
    padding-left: 6.667rem !important;
  }

  .px-md-gutter {
    padding-right: 2.133rem !important;
    padding-left: 2.133rem !important;
  }

  .px-md-out-container {
    padding-right: calc(50% - 50vw) !important;
    padding-left: calc(50% - 50vw) !important;
  }

  .py-md-sm {
    padding-top: 0.33335rem !important;
    padding-bottom: 0.33335rem !important;
  }

  .py-md-3px {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .py-md-15px {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .py-md-25px {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .py-md-35px {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .py-md-n15 {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }

  .py-md-n1 {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }

  .py-md-n5 {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.6667rem !important;
    padding-bottom: 0.6667rem !important;
  }

  .py-md-2 {
    padding-top: 1.3334rem !important;
    padding-bottom: 1.3334rem !important;
  }

  .py-md-3 {
    padding-top: 2.0001rem !important;
    padding-bottom: 2.0001rem !important;
  }

  .py-md-4 {
    padding-top: 2.6668rem !important;
    padding-bottom: 2.6668rem !important;
  }

  .py-md-5 {
    padding-top: 3.3335rem !important;
    padding-bottom: 3.3335rem !important;
  }

  .py-md-6 {
    padding-top: 4.0002rem !important;
    padding-bottom: 4.0002rem !important;
  }

  .py-md-7 {
    padding-top: 4.6669rem !important;
    padding-bottom: 4.6669rem !important;
  }

  .py-md-8 {
    padding-top: 5.3336rem !important;
    padding-bottom: 5.3336rem !important;
  }

  .py-md-9 {
    padding-top: 6.0003rem !important;
    padding-bottom: 6.0003rem !important;
  }

  .py-md-10 {
    padding-top: 6.667rem !important;
    padding-bottom: 6.667rem !important;
  }

  .py-md-gutter {
    padding-top: 2.133rem !important;
    padding-bottom: 2.133rem !important;
  }

  .py-md-out-container {
    padding-top: calc(50% - 50vw) !important;
    padding-bottom: calc(50% - 50vw) !important;
  }

  .pt-md-sm {
    padding-top: 0.33335rem !important;
  }

  .pt-md-3px {
    padding-top: 3px !important;
  }

  .pt-md-15px {
    padding-top: 15px !important;
  }

  .pt-md-25px {
    padding-top: 25px !important;
  }

  .pt-md-35px {
    padding-top: 35px !important;
  }

  .pt-md-n15 {
    padding-top: -15px !important;
  }

  .pt-md-n1 {
    padding-top: -10px !important;
  }

  .pt-md-n5 {
    padding-top: -5px !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.6667rem !important;
  }

  .pt-md-2 {
    padding-top: 1.3334rem !important;
  }

  .pt-md-3 {
    padding-top: 2.0001rem !important;
  }

  .pt-md-4 {
    padding-top: 2.6668rem !important;
  }

  .pt-md-5 {
    padding-top: 3.3335rem !important;
  }

  .pt-md-6 {
    padding-top: 4.0002rem !important;
  }

  .pt-md-7 {
    padding-top: 4.6669rem !important;
  }

  .pt-md-8 {
    padding-top: 5.3336rem !important;
  }

  .pt-md-9 {
    padding-top: 6.0003rem !important;
  }

  .pt-md-10 {
    padding-top: 6.667rem !important;
  }

  .pt-md-gutter {
    padding-top: 2.133rem !important;
  }

  .pt-md-out-container {
    padding-top: calc(50% - 50vw) !important;
  }

  .pe-md-sm {
    padding-right: 0.33335rem !important;
  }

  .pe-md-3px {
    padding-right: 3px !important;
  }

  .pe-md-15px {
    padding-right: 15px !important;
  }

  .pe-md-25px {
    padding-right: 25px !important;
  }

  .pe-md-35px {
    padding-right: 35px !important;
  }

  .pe-md-n15 {
    padding-right: -15px !important;
  }

  .pe-md-n1 {
    padding-right: -10px !important;
  }

  .pe-md-n5 {
    padding-right: -5px !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.6667rem !important;
  }

  .pe-md-2 {
    padding-right: 1.3334rem !important;
  }

  .pe-md-3 {
    padding-right: 2.0001rem !important;
  }

  .pe-md-4 {
    padding-right: 2.6668rem !important;
  }

  .pe-md-5 {
    padding-right: 3.3335rem !important;
  }

  .pe-md-6 {
    padding-right: 4.0002rem !important;
  }

  .pe-md-7 {
    padding-right: 4.6669rem !important;
  }

  .pe-md-8 {
    padding-right: 5.3336rem !important;
  }

  .pe-md-9 {
    padding-right: 6.0003rem !important;
  }

  .pe-md-10 {
    padding-right: 6.667rem !important;
  }

  .pe-md-gutter {
    padding-right: 2.133rem !important;
  }

  .pe-md-out-container {
    padding-right: calc(50% - 50vw) !important;
  }

  .pb-md-sm {
    padding-bottom: 0.33335rem !important;
  }

  .pb-md-3px {
    padding-bottom: 3px !important;
  }

  .pb-md-15px {
    padding-bottom: 15px !important;
  }

  .pb-md-25px {
    padding-bottom: 25px !important;
  }

  .pb-md-35px {
    padding-bottom: 35px !important;
  }

  .pb-md-n15 {
    padding-bottom: -15px !important;
  }

  .pb-md-n1 {
    padding-bottom: -10px !important;
  }

  .pb-md-n5 {
    padding-bottom: -5px !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.6667rem !important;
  }

  .pb-md-2 {
    padding-bottom: 1.3334rem !important;
  }

  .pb-md-3 {
    padding-bottom: 2.0001rem !important;
  }

  .pb-md-4 {
    padding-bottom: 2.6668rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3.3335rem !important;
  }

  .pb-md-6 {
    padding-bottom: 4.0002rem !important;
  }

  .pb-md-7 {
    padding-bottom: 4.6669rem !important;
  }

  .pb-md-8 {
    padding-bottom: 5.3336rem !important;
  }

  .pb-md-9 {
    padding-bottom: 6.0003rem !important;
  }

  .pb-md-10 {
    padding-bottom: 6.667rem !important;
  }

  .pb-md-gutter {
    padding-bottom: 2.133rem !important;
  }

  .pb-md-out-container {
    padding-bottom: calc(50% - 50vw) !important;
  }

  .ps-md-sm {
    padding-left: 0.33335rem !important;
  }

  .ps-md-3px {
    padding-left: 3px !important;
  }

  .ps-md-15px {
    padding-left: 15px !important;
  }

  .ps-md-25px {
    padding-left: 25px !important;
  }

  .ps-md-35px {
    padding-left: 35px !important;
  }

  .ps-md-n15 {
    padding-left: -15px !important;
  }

  .ps-md-n1 {
    padding-left: -10px !important;
  }

  .ps-md-n5 {
    padding-left: -5px !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.6667rem !important;
  }

  .ps-md-2 {
    padding-left: 1.3334rem !important;
  }

  .ps-md-3 {
    padding-left: 2.0001rem !important;
  }

  .ps-md-4 {
    padding-left: 2.6668rem !important;
  }

  .ps-md-5 {
    padding-left: 3.3335rem !important;
  }

  .ps-md-6 {
    padding-left: 4.0002rem !important;
  }

  .ps-md-7 {
    padding-left: 4.6669rem !important;
  }

  .ps-md-8 {
    padding-left: 5.3336rem !important;
  }

  .ps-md-9 {
    padding-left: 6.0003rem !important;
  }

  .ps-md-10 {
    padding-left: 6.667rem !important;
  }

  .ps-md-gutter {
    padding-left: 2.133rem !important;
  }

  .ps-md-out-container {
    padding-left: calc(50% - 50vw) !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .fs-md-1 {
    font-size: 2.5rem !important;
  }

  .fs-md-2 {
    font-size: 2rem !important;
  }

  .fs-md-3 {
    font-size: 1.75rem !important;
  }

  .fs-md-4 {
    font-size: 1.5rem !important;
  }

  .fs-md-5 {
    font-size: 1.25rem !important;
  }

  .fs-md-6 {
    font-size: 1.125rem !important;
  }

  .fs-md-7 {
    font-size: 1rem !important;
  }

  .fs-md-lg {
    font-size: 1.875rem !important;
  }

  .fs-md-sm {
    font-size: 0.875rem !important;
  }

  .fs-md-xs {
    font-size: 0.75rem !important;
  }

  .fs-md-xxs {
    font-size: 0.625rem !important;
  }
}